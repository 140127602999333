<template>
  <div
    class="col-md-12"
    v-if="this.translateData"
  >
    <div class="card card-container">
      <Form
        @submit="handleRegister"
        :validation-schema="schema"
      >
        <div v-if="!successful">
          <div class="form-group">
            <Field
              name="name"
              type="text"
              placeholder="Name"
              class="form-control"
            />
            <ErrorMessage
              name="name"
              class="error-feedback"
            />
          </div>
          <div class="form-group">
            <Field
              name="email"
              type="email"
              placeholder="Email"
              class="form-control"
            />
            <ErrorMessage
              name="email"
              class="error-feedback"
            />
          </div>
          <div class="form-group">
            <Field
              name="password"
              type="password"
              placeholder="Password"
              class="form-control"
            />
            <ErrorMessage
              name="password"
              class="error-feedback"
            />
          </div>
          <div class="form-group">
            <Field
              name="password_confirmation"
              type="password"
              placeholder="Password confirmation"
              class="form-control"
            />
            <ErrorMessage
              name="password_confirmation"
              class="error-feedback"
            />
          </div>
          <div class="form-group">
            <vue-recaptcha
              ref="recaptcha"
              @error="errorRecaptcha"
              @verify="verifyRecaptcha"
              sitekey="6LfxzyIeAAAAAGfS5I9ZUq9Rt0eAnrYLlOqkHIOV"
            />
          </div>
          <div class="form-group">
            <div class="registration__confirm-container">
              <div class="registration__confirm-checkbox">
                <Field
                  name="agreement"
                  type="checkbox"
                  id="agreement"
                  :value="true"
                />
                <label for="agreement">
                  <CheckSmall />
                </label>
              </div>
              <div class="registration__confirm">
                {{ this.translateData.i_agree_to }}
                <router-link
                  to="/confirm"
                  target="_blank"
                >{{ this.translateData.users_terms }}</router-link>
              </div>
            </div>
            <ErrorMessage
              name="agreement"
              class="error-feedback"
            />
          </div>

          <div class="form-group">
            <button class="registration__button">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              {{ this.translateData.register_button }}
            </button>
          </div>
        </div>
      </Form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <div
        v-if="error"
        class="alert alert-danger-ref-code"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ error }}
      </div>
    </div>
    <DefaultPopup
      v-if="isModalOpen"
      :message="this.dataPopup"
      :on-close="handleCloseModal"
    >
    </DefaultPopup>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { VueRecaptcha } from 'vue-recaptcha'

import CheckSmall from '@/assets/svg/check-small.svg?inline'

import DefaultPopup from '@/components/molecules/DefaultPopup.vue'

export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage,
    DefaultPopup,
    CheckSmall,
    VueRecaptcha
  },
  data () {
    const schema = yup.object().shape({
      name: yup
        .string()
        .required('Username is required!')
        .min(3, 'Must be at least 3 characters!')
        .max(20, 'Must be maximum 20 characters!'),
      email: yup
        .string()
        .required('Email is required!')
        .email('Email is invalid!')
        .max(50, 'Must be maximum 50 characters!'),
      password: yup
        .string()
        .required('Password is required!')
        .min(6, 'Must be at least 6 characters!')
        .max(40, 'Must be maximum 40 characters!'),
      password_confirmation: yup
        .string()
        .required('Password confirmation is required!')
        .min(6, 'Must be at least 6 characters!')
        .max(40, 'Must be maximum 40 characters!'),
      agreement: yup
        .bool().required('Accept user agreement!')
    })

    return {
      successful: false,
      loading: false,
      message: '',
      error: '',
      schema,
      isModalOpen: false,
      translateData: {},
      recaptcha: false,
      dataPopup:
        'Ссылка для подверждения емейла была отправлена на почту. Пожалуйста, перейдите по ссылке в письме. Если не нашли письма - проверьте папку спам.'
    }
  },
  mounted () {
    if (this.loggedIn) {
      this.$router.push('/dashboard/home')
    }
    this.translate()
  },
  computed: {
    triggerAcceptAgreement () {
      const accept = this.$route.query
      if (accept.accept === 'true') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('i_agree_to', 'users_terms', 'register_button', 'forgot_password_button')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    verifyRecaptcha () {
      this.recaptcha = true
    },
    errorRecaptcha () {
      this.recaptcha = false
    },
    handleRegister (user) {
      this.message = ''
      if (this.recaptcha) {
        this.successful = false
        this.loading = true
        user.ref_code = this.$route.query.ref
        this.$store.dispatch('auth/register', user).then(
          (data) => {
            this.message = data.message
            this.successful = true
            this.loading = false
            this.$store.dispatch('auth/login', user).then(() => {
              this.isModalOpen = true
              this.$store.dispatch('getPayments/resend')
            })
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            this.successful = false
            this.loading = false
            this.error = error.response.data.errors.ref_code[0]
          }
        )
      }
    },
    handleCloseModal () {
      this.$router.push('/dashboard/home')
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-danger-ref-code {
  color: red;
}
.form-control {
  border-radius: 15px;
  border: 1px solid #b2b4b9;
  width: 400px;
  height: 48px;
  padding-left: 10px;
  margin-bottom: 15px;
}

.form-group {
  position: relative;
  padding-bottom: 10px;
}

.error-feedback {
  color: $secondColor;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.registration {
  &__button {
    @include third-button;
    &:hover {
      @include hover-third-button;
    }
    &:active {
      background: #ffeb36;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      color: #1e1f23;
    }
  }

  &__confirm-container {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 10px 0 20px 0;
  }

  &__confirm-checkbox {
    input {
      visibility: hidden;
      position: absolute;
    }

    label {
      min-width: 24px;
      display: block;
      height: 24px;
      border: 2px solid #bebebe;
      margin-right: 12px;
      border-radius: 5px;

      svg {
        display: none;
      }
    }

    input:checked + label {
      display: flex;
      svg {
        margin: auto;
        display: block;
      }
    }
  }

  &__confirm {
    text-align: left;

    a {
      color: #7caf22;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $break-sm) {
  .form-control {
    width: 100%;
  }
  .registration {
    &__button {
      height: 40px;
      border-radius: 12px;
    }
    &__confirm {
      a {
        font-size: 14px;
      }
    }
    &__confirm-container {
      padding: 10px 0;
    }
    .form-control {
      height: 40px;
      margin-bottom: 5px;
      border-radius: 12px;
    }
  }
}
</style>
