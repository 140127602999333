import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d71dbba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup-registration"
}
const _hoisted_2 = { class: "popup-registration__container" }
const _hoisted_3 = { class: "popup-registration__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegistrationForm = _resolveComponent("RegistrationForm")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(this.translateData.registration_header), 1),
          _createVNode(_component_RegistrationForm)
        ])
      ]))
    : _createCommentVNode("", true)
}