
import { defineComponent } from 'vue'
import RegistrationSection from '@/components/molecules/PopupRegistration.vue'
import SecondLayout from '@/templates/SecondLayout.vue'

export default defineComponent({
  name: 'Registration',
  components: {
    RegistrationSection,
    SecondLayout
  }
})
