
import { defineComponent } from 'vue'
import RegistrationForm from '@/components/forms/Registration.vue'

export default defineComponent({
  name: 'PopupRegistration',
  data () {
    return {
      translateData: {}
    }
  },
  props: {
    onClose: {
      type: Function,
      default () {
        return {}
      }
    }
  },
  components: {
    RegistrationForm
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('registration_header')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
